<template>
  <div>
    <div v-if="authenticated && !authorizationFailed">
      <b-navbar />
      <div class="container" style="min-width: 992px">
        <router-view />
        <b-card v-if="notFound">
          <template v-slot:title>Not Found</template> 
          <template v-slot:body>
            <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
            <b-button class="secondary me-1" @click="goBack">Back</b-button>
            <b-button @click="goHome">Home</b-button>
          </template> 
        </b-card>
        <div class="border-top mb-3 mt-3"></div>
        <div class="row mb-3">
          <div class="col-6">&copy; 118 Group {{ $moment().format("YYYY") }}</div>
          <div class="col-6 text-end">Version {{ version }} (API version {{ apiVersion ?? 'unknown' }})</div>
        </div>
      </div>
    </div>
    <div class="toast-container">
      <b-toast v-for="toast in toasts" :key="toast.id" :toast="toast" @close="closeToast" />
    </div>
    <b-modal v-for="modal in modals" :key="modal.id" @close="closeModal(modal.id)">
      <template v-slot:title>{{ modal.title ?? "Notice" }}</template>
      <template v-slot:body>{{ modal.text }}</template>
      <template v-slot:actions>
          <b-button class="secondary" @click="closeModal(modal.id)" v-focus>Close</b-button>
      </template>
    </b-modal>
    <b-modal v-for="error in errors" :key="error.id" @close="closeModal(modal.id)">
      <template v-slot:title>Error</template>
      <template v-slot:body>
        <p>An unhandled exception has occurred - please see details below</p>
        <textarea class="form-control" rows="4" disabled :value="error.text"></textarea>
      </template>
      <template v-slot:actions>
          <b-button class="secondary" @click="closeError(error.id)" v-focus>Close</b-button>
      </template>
    </b-modal>
    <b-modal v-if="apiConnectionFailed" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>Connection to API failed - please try again</template>
      <template v-slot:actions>
          <b-button @click="getApiVersion" v-focus>Retry</b-button>
      </template>
    </b-modal>
    <b-modal v-if="authenticationFailed" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>Connection to authentication server failed - please try again</template>
      <template v-slot:actions>
          <b-button @click="getAuthentication" v-focus>Retry</b-button>
      </template>
    </b-modal>
    <b-modal v-if="authorizationFailed" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>You are not authorized to use this application - please try again</template>
      <template v-slot:actions>
          <b-button @click="logOut" v-focus>Log Out</b-button>
          <b-button @click="getAuthentication" v-focus>Retry</b-button>
      </template>
    </b-modal>
    <div v-if="axiosRequests > 0" class="spinner-mask">
      <div class="spinner-border text-light"></div>
    </div>
    <b-modal v-if="apiVersion && !versionMatch" :hide-close="true">
      <template v-slot:title>Error</template>
      <template v-slot:body>App version ({{ version }}) does not match API version ({{ apiVersion }}) - if you are seeing this an update is likely in progress, please try again shortly</template>
      <template v-slot:actions>
          <b-button @click="getApiVersion" v-focus>Retry</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applicationName: require('../package.json').friendlyName,
      keycloakClient: require('../package.json').name,
      version: require('../package.json').version,
      navigation: require('./navigation.json'),
      modals: [],
      toasts: [],
      errors: [],
      axiosRequests: 0,
      apiConnectionFailed: false,
      apiUrl: '/api',
      apiVersion: null,
      authenticated: null,
      authenticationFailed: false,
      authorizationFailed: false,
      isDevelopment: false,
      skipAuthentication: true,
      isAdmin: false
    }
  },
  computed: {
    versionMatch() {
      return this.version == this.apiVersion;
    },
    notFound() {
      return this.$route.matched.length == 0;
    }
  },
  created() {
    this.isDevelopment = window.location.host.includes("localhost");

    if(this.isDevelopment) {
      this.apiUrl = "https://localhost:8443/api";
    }

    this.getAuthentication();
  },
  mounted() {
    var _this = this;

    this.$http.interceptors.request.use(function (config) {
      _this.axiosRequests++;
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    this.$http.interceptors.response.use(function (response) {
      _this.axiosRequests--;
      return response;
    }, function (error) {
      _this.axiosRequests--;
      return Promise.reject(error);
    });
  },
  methods: {
    stringNullOrWhitespace(string) {
      return string == null || string.trim() === '';
    },
    goHome() {
      window.location = '/';
    },
    goBack() {
      history.back();
    },
    async getAuthentication() {
      this.getApiVersion();

      if (this.isDevelopment && this.skipAuthentication) {
        this.authenticated = true;
        this.isAdmin = true;
      }

      else {
        this.$keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then((auth) => {
          if (!auth) {
              window.location.reload();
          } else {
              this.authenticated = true;
              
              if(!this.$keycloak.realmAccess.roles.includes(this.keycloakClient)) {
                this.authorizationFailed = true;
              }
              
              if(this.$keycloak.realmAccess.roles.includes('bulkload-admin')) {
                this.isAdmin = true;
              }
          }

          //Token Refresh
          setInterval(() => {
              this.$keycloak.updateToken(70).then(() => {
                if(!this.$keycloak.realmAccess.roles.includes(this.keycloakClient)) {
                  this.authorizationFailed = true;
                }

                if(this.$keycloak.realmAccess.roles.includes('bulkload-admin')) {
                this.isAdmin = true;
                }
              }).catch(() => {
                this.authenticated = false;
                this.authenticationFailed = true;
                this.isAdmin = false;
              });
          }, 6000)
        }).catch(() => {
          this.authenticated = false;
          this.authenticationFailed = true;
          this.isAdmin = false;
        });
      }
    },
    async getApiVersion() {
      var response = await this.get(this.apiUrl + "/version");

      this.apiVersion = response.success ? response.data : null;
    },
    logOut() {
      var logoutOptions = { redirectUri : window.location.href };
 
      this.$keycloak.logout(logoutOptions).catch((error) => {
        this.showError(error);
      });
    },
    showToast(text, warning = false) {
      var toast = {
        id: this.$guid(),
        text,
        warning,
        time: this.$moment().format("HH:mm:ss")
      };
      
      this.toasts.push(toast);

      if (!warning) {
        setTimeout(() => this.closeToast(toast.id), 2500);
      }
    },
    closeToast(id) {
      this.toasts = this.toasts.filter(t => t.id != id);
    },
    showModal(text, title = null) {
      this.modals.push({
        id: this.$guid(),
        text,
        title
      });
    },
    closeModal(id) {
      this.modals = this.modals.filter(m => m.id != id);
    },
    showError(text) {
      this.errors.push({
        id: this.$guid(),
        text
      });
    },
    closeError(id) {
      this.errors = this.errors.filter(e => e.id != id);
    },
    updateHeaders() {
      if (this.authenticated) {
        this.$http.defaults.headers.common["Authorization"] = "Bearer " +  this.$keycloak.token;
      }
      else
      {
        delete this.$http.defaults.headers.common["Authorization"];
      }
    },
    handleResponse(response) {
      this.apiConnectionFailed = false;
      
      if (!response.data.success) {
        if (!response.data.unexpected) {
          this.showModal(response.data.message);
        }
        else {
          this.showError(response.data.message);
        }
      }

      return response.data;
    },
    handleError(error) {
      if (!error.response) {
        this.apiConnectionFailed = true;
      }
      else if (error.response.status == 401 && this.modals.filter(m => m.text == "You must log in to view this page").length == 0) {
        this.showModal("You must log in to view this page");
      }
      else if (error.response.status == 403 && this.modals.filter(m => m.text == "You do not have permission to perform this action").length == 0) {
        this.showModal("You do not have permission to perform this action");
      }
      else {
        this.showError(JSON.stringify(error.response, null, '\t'));
      }

      return {
        success: false
      };
    },
    async get(url, data) {
      try {
        this.updateHeaders();

        var params = data ? {
          params: data
        } : null;

        return this.handleResponse(await this.$http.get(url, params));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async getHeaders(url, data, key, value) {
      try {
        delete this.$http.defaults.headers.common["Authorization"];

        this.$http.defaults.headers.common[key] = value;

        var params = data ? {
          params: data
        } : null;

        return this.handleResponse(await this.$http.get(url, params));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async delete(url, data) {
      try {
        this.updateHeaders();

        var params = data ? {
          params: data
        } : null;

        return this.handleResponse(await this.$http.delete(url, params));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async post(url, data) {
      try {
        this.updateHeaders();

        return this.handleResponse(await this.$http.post(url, data));
      }
      catch (error) {
        return this.handleError(error);
      }
    },
    async patch(url, data) {
      try {
        this.updateHeaders();

        return this.handleResponse(await this.$http.patch(url, data));
      }
      catch (error) {
        return this.handleError(error);
      }
    }
  }
}
</script>

<style>
@import '@118group/vuecomponents/dist/library.mjs.css';

table {
  /* table-layout: fixed; */
  word-wrap: normal;
}

.table-buttons {
  text-align: right;
  white-space: nowrap;
}

.toast-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1070;
}

.spinner-mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 10000;
}

.spinner-border {
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  position: absolute;
  z-index: 100000;
}

.modal-separator {
  border-top: 1px solid #DEE2E6;
  margin: 1rem -1rem;
}

.form-control {
  position: relative;
}
</style>